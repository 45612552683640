$poppinsFont: 'Poppins', sans-serif;
$robotoFont: 'Roboto', sans-serif;
$globalFont: $robotoFont;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-extra-light: #fda92d14;
$primary-light: #fda92d29;
$primary-darker: #fda92d79;
$primary-main: #fda92d;
$primary-dark: #b66816;
$primary-extra-dark: #793908;

$primary-button-background: #fda92d14;
$primary-button-background-hover: #fda92d29;
$primary-button-shadow: rgba(34, 61, 60, 0.24) 0 0.5rem 1rem 0;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-text: #d9d9d9;
$color-grey-icon: #131a29;
$color-grey-border: #ededed;
$color-orange: #e9471d;
$color-orange-darker: #ee4216;
$color-orange-darkest: #c7350f;

$label: rgb(145, 158, 171);
$label-focused: $primary-main;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$black-color: #000000;

$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-brighter: #efc5bd;

$green: rgb(34, 154, 22);
$light-green: #b6d0ad;
$very-light-green: #eef6df;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$input-color: #919eab;
$input-border-color: rgba(145, 158, 171, 0.32);
$input-hover-color: rgba(0, 0, 0, 0.87);
$input-focus-color: #2d5452;
